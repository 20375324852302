// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EntityService } from '../core/entity/entity.service';
import { AlertService } from '../shared/alert.service';
import { Country } from './country.model';

@Injectable({
  providedIn: 'root',
})
export class CountryService extends EntityService<Country> {
  constructor(
    protected alertService: AlertService,
    protected httpClient: HttpClient,
  ) {
    super(alertService, httpClient);
  }

  getAllByShortName$(shortName: string): Observable<Country[]> {
    const req$ = super.getAll$({ short_name: shortName });

    return this.handleGetOrCreateResponse(req$, {
      log: `Get ${this.pluralIdentifier()} by short name(${shortName}) failed:`,
      nice: `Failed to get ${this.pluralIdentifier()}`,
    });
  }

  pluralIdentifier(): string {
    return 'countries';
  }

  singularIdentifier(): string {
    return 'country';
  }
}
